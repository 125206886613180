
import { Filter } from '@/types/ListTypes';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { convertToDateTimeToLocaleString } from '@/helpers/converters/date';
import useTenantSettingsStore from '@/stores/tenantSettings';

// Lazy loading imports
const RangeFilterDisplayValues = () => import(
  /* webpackChunkName: "range-filter-display-values" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/RangeFilterDisplayValues.vue'
);
const MultiValueFilterDisplayValues = () => import(
  /* webpackChunkName: "multi-value-filter-display-values" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/MultiValueFilterDisplayValues.vue'
);
const SingleValueFilterDisplayValue = () => import(
  /* webpackChunkName: "single-value-filter-display-value" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableFilter/SingleValueFilterDisplayValue.vue'
);

@Component({
  name: 'SelectedFilterValues',
  components: {
    RangeFilterDisplayValues,
    MultiValueFilterDisplayValues,
    SingleValueFilterDisplayValue,
  },
})
export default class SelectedFilterValues extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  private tenantSettings = useTenantSettingsStore();

  protected getConverter(): (data: number|string) => string {
    switch (this.getFilterType()) {
      case 'date-range':
        return (data: string|number) => convertToDateTimeToLocaleString(
          (data).toString(),
          this.tenantSettings.datetimeLocale,
          {
            year: 'numeric',
            month: this.filter.metadata?.human_moments ? 'long' : 'numeric',
            day: 'numeric',
          },
        );
      default:
        return (data: number|string) => (data).toString();
    }
  }

  protected getFilterType(): string {
    return this.filter.metadata?.type as string;
  }

  protected filterIsOfType(type: string): boolean {
    switch (this.getFilterType()) {
      case 'range':
      case 'date-range':
      case 'datetime-range':
      case 'currency-range':
        return type === 'rangeValues';
      case 'multiselect':
      case 'boolean-select':
      case 'assignments':
      case 'tags':
        return type === 'multiValues';
      case 'select':
        return type === 'singleValue';
      default:
        return type === 'singleValue';
    }
  }
}
